import revive_payload_client_UE6oixl10N from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@types+node@22.10.9_eslint@8.57.1_rollup@4.31.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JWFUN6k6nL from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@types+node@22.10.9_eslint@8.57.1_rollup@4.31.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_uwcyJEosUx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@types+node@22.10.9_eslint@8.57.1_rollup@4.31.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_4C8Erk5HUQ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_3p7doK1NcJ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.0.6_rollup@4.31.0_vite@6.0.11_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import navigation_repaint_client_H3tZiDVcQ7 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@types+node@22.10.9_eslint@8.57.1_rollup@4.31.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_l5rt6nlxxi from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@types+node@22.10.9_eslint@8.57.1_rollup@4.31.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_mIYv1CkqMX from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@types+node@22.10.9_eslint@8.57.1_rollup@4.31.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_miLLfqXSyV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@types+node@22.10.9_eslint@8.57.1_rollup@4.31.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import documentDriven_2CtV5NXnlT from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+content@2.13.4_nuxt@3.15.2_rollup@4.31.0_vue@3.5.13/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import siteConfig_09l0Em8lES from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.31.0_vite@6.0.11_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_MlBdxfEfXp from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.31.0_vite@6.0.11_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Bns9riFv2d from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.31.0_vite@6.0.11_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_gappKGFb4E from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.31.0_vite@6.0.11_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import preview_client_ti5nYiEDQZ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxthq+studio@2.2.1_rollup@4.31.0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import switch_locale_path_ssr_MVdq7HidjO from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.31.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_OFCL3XZPy8 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.31.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import scrollTo_VfGcxQgQRt from "/opt/buildhome/repo/app/plugins/scrollTo.ts";
import stripe_AAICqGovyW from "/opt/buildhome/repo/app/plugins/stripe.ts";
import sentry_h2FqLeojBn from "/opt/buildhome/repo/app/plugins/sentry.ts";
import fernand_jBadTnQrDK from "/opt/buildhome/repo/app/plugins/fernand.ts";
import posthog_cSgRgsM4qp from "/opt/buildhome/repo/app/plugins/posthog.ts";
export default [
  revive_payload_client_UE6oixl10N,
  unhead_JWFUN6k6nL,
  router_uwcyJEosUx,
  supabase_client_4C8Erk5HUQ,
  _0_siteConfig_3p7doK1NcJ,
  navigation_repaint_client_H3tZiDVcQ7,
  check_outdated_build_client_l5rt6nlxxi,
  chunk_reload_client_mIYv1CkqMX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_miLLfqXSyV,
  documentDriven_2CtV5NXnlT,
  siteConfig_09l0Em8lES,
  inferSeoMetaPlugin_MlBdxfEfXp,
  titles_Bns9riFv2d,
  defaultsWaitI18n_gappKGFb4E,
  preview_client_ti5nYiEDQZ,
  switch_locale_path_ssr_MVdq7HidjO,
  i18n_OFCL3XZPy8,
  scrollTo_VfGcxQgQRt,
  stripe_AAICqGovyW,
  sentry_h2FqLeojBn,
  fernand_jBadTnQrDK,
  posthog_cSgRgsM4qp
]