<script lang="ts" setup>
import { onKeyStroke } from '@vueuse/core'

defineProps<{
  isOpen: boolean;
  isFullscreen: boolean;
}>()

const emit = defineEmits(['close'])

onKeyStroke('Escape', () => {
  emit('close')
})
</script>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <div v-if="isOpen" :class="['dialog', { 'is-fullscreen': isFullscreen }]">
        <div class="dialog-content">
          <span class="close-button">
            <MPButton
              icon="ri-close-line"
              size="large"
              background-color="transparent"
              link
              @click="emit('close')"
            />
          </span>
          <slot/>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="postcss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.dialog {
  all: unset;
  position: fixed;
  z-index: 9000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  width: fit-content;
  height: fit-content;
  max-width: 100vw;
  max-height: 100vh;
  background-color: var(--main-background-color);
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
      display: none;
  }
}

.dialog.is-fullscreen {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: none;
  border-radius: 0;
}

.dialog-content {
  position: relative;
  padding: 1.5rem 1rem;
  width: 100%;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>
