<script setup>
import { ref } from 'vue'
const isActive = ref(true)
const props = defineProps({
  videoSrc: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  imageCover: {
    type: String,
    required: true
  },
  copyrightHolder: {
    type: String,
    required: true
  }
})
</script>

<template>
  <div class="for-explorers-card" :class="{ isActive }">
    <video playsinline autoplay muted loop :poster="props.imageCover">
      <source :src="props.videoSrc" type="video/webm">
      <meta :itemprop="props.copyrightHolder" :content="props.copyrightHolder">
    </video>
    <div class="overlay">
      <h2>{{ props.title }}</h2>
      <p>{{ props.description }}</p>
    </div>
  </div>
</template>

<style scoped lang="postcss">
@import '@/assets/css/breakpoints.pcss';
@import '@/assets/css/typography.pcss';

.for-explorers-card {
  height: 100%;
  width: 100%;
  position: relative;

  video {
    border-radius: 1rem;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  img {
    border-radius: 1rem;
    width: 100%;
  }

  .overlay {
    box-sizing: border-box;
    position: absolute;
    top: -1px;
    left: -1px;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #070707 100%);
    color: var(--color-slate-100);
    border-radius: 1rem;
    align-content: end;
    padding: 0 1.5rem 0.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    place-content: end;

    button {
      background-color: var(--color-slate-50);
      color: var(--color-slate-900);
      width: 100%;
      text-align: left
    }

    h2 {
      @mixin typeface-h5;
      font-weight: 500;
      margin: 0 0 0.5rem;
    }

    p {
      @mixin typeface-regular;
      color: var(--color-slate-200);
    }
  }
}
</style>
