import revive_payload_client_4YvkbBzzJy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_eslint@8.57.1_rollup@4.32.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Mz9yz2oq9u from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_eslint@8.57.1_rollup@4.32.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_F6FzZNxVno from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_eslint@8.57.1_rollup@4.32.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_4C8Erk5HUQ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_5Qhs8E2uJG from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.0.6_rollup@4.32.0_vite@6.0.11_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import navigation_repaint_client_QlTfWe40RN from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_eslint@8.57.1_rollup@4.32.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_23oxPb4ILZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_eslint@8.57.1_rollup@4.32.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_n0fk47DUYs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_eslint@8.57.1_rollup@4.32.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_7kI0Mjeaqm from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_eslint@8.57.1_rollup@4.32.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import documentDriven_WgEWf6LP97 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+content@2.13.4_nuxt@3.15.3_rollup@4.32.0_vue@3.5.13/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import siteConfig_hU8YW0SQN6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.32.0_vite@6.0.11_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_qArsN0YAmF from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.32.0_vite@6.0.11_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_OZLd7P1L6h from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.32.0_vite@6.0.11_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_iZBTZIBmby from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.32.0_vite@6.0.11_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import preview_client_fdufGlK4pd from "/opt/buildhome/repo/node_modules/.pnpm/@nuxthq+studio@2.2.1_rollup@4.32.0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import switch_locale_path_ssr_03OB41YwZi from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.32.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_9NCah4IYxQ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.32.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import scrollTo_VfGcxQgQRt from "/opt/buildhome/repo/app/plugins/scrollTo.ts";
import stripe_AAICqGovyW from "/opt/buildhome/repo/app/plugins/stripe.ts";
import sentry_h2FqLeojBn from "/opt/buildhome/repo/app/plugins/sentry.ts";
import fernand_jBadTnQrDK from "/opt/buildhome/repo/app/plugins/fernand.ts";
import posthog_cSgRgsM4qp from "/opt/buildhome/repo/app/plugins/posthog.ts";
export default [
  revive_payload_client_4YvkbBzzJy,
  unhead_Mz9yz2oq9u,
  router_F6FzZNxVno,
  supabase_client_4C8Erk5HUQ,
  _0_siteConfig_5Qhs8E2uJG,
  navigation_repaint_client_QlTfWe40RN,
  check_outdated_build_client_23oxPb4ILZ,
  chunk_reload_client_n0fk47DUYs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7kI0Mjeaqm,
  documentDriven_WgEWf6LP97,
  siteConfig_hU8YW0SQN6,
  inferSeoMetaPlugin_qArsN0YAmF,
  titles_OZLd7P1L6h,
  defaultsWaitI18n_iZBTZIBmby,
  preview_client_fdufGlK4pd,
  switch_locale_path_ssr_03OB41YwZi,
  i18n_9NCah4IYxQ,
  scrollTo_VfGcxQgQRt,
  stripe_AAICqGovyW,
  sentry_h2FqLeojBn,
  fernand_jBadTnQrDK,
  posthog_cSgRgsM4qp
]