import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@types+node@22.10.9_eslint@8.57.1_rollup@4.31.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/validate.js";
import metrics_45global from "/opt/buildhome/repo/app/middleware/metrics.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@types+node@22.10.9_eslint@8.57.1_rollup@4.31.0_sass@1.83.4_typescript@5.7.3_vite@6.0.11_vue-tsc@2.2.0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  metrics_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "404": () => import("/opt/buildhome/repo/app/middleware/404.ts"),
  auth: () => import("/opt/buildhome/repo/app/middleware/auth.ts")
}