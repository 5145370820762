<script lang="ts" setup>
const dialogTarget = ref<InstanceType<typeof MPDialog>>(null)
const { setDialogRef, closeDialog } = useDialog()
const { fetchCurrentUser, currentUser } = useAuth()
const route = useRoute()
const router = useRouter()

const onSuccess = async () => {
  await fetchCurrentUser()
  if (currentUser.value) closeDialog()
}
const subscriptionSuccessful = ref(false)
onMounted(() => {
  setDialogRef(dialogTarget)
  // check if redirect from successfull after stripe checkout
  if (route.query.payment === 'success') {
    subscriptionSuccessful.value = true
    setTimeout(() => {
      subscriptionSuccessful.value = false
    }, 5000)
    router.replace({ query: undefined })
  }
})
</script>

<template>
  <div>
    <ErrorBoundry>
      <div class="flex-wrapper">
        <div>
          <MPHeader />
        </div>
        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
        <MPFooter class="footer"/>
      </div>
      <MPDialog ref="dialogTarget">
        <MPAuth
          v-if="!route.path.includes('auth')"
          :title="$t('auth.title')"
          :summary="$t('auth.summary')"
          @on-auth-success="onSuccess"
          @on-signup-success="onSuccess"
        />
        <!-- // esle subscribe -->
      </MPDialog>
      <MPToast />
      <MPMessage v-if="subscriptionSuccessful" version="success" class="subscription-success">
        <h4>Payment successfully processed.</h4>
        <p>Thank you for your support!</p>
      </MPMessage>
    </ErrorBoundry>
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/breakpoints.pcss";

.subscription-success {
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  min-width: 26rem;
  height: fit-content;
  background-color: var(--color-green-50);
  color: var(--color-green-900);
  z-index: 100;
  text-align: center;
  z-index: 9999;
  h4 {
    margin: 0.5rem;
  }
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
  }
}
.footer {
  margin-top: auto;
}
</style>
