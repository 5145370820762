<script setup lang="ts">
const isLessThanSm = ref<boolean>(true)

const elHeader = ref(null)
useResizeObserver(elHeader, (entries) => {
  const { width } = entries[0].contentRect
  isLessThanSm.value = width <= 768
})
</script>

<template>
  <div ref="elHeader" :class="{ 'overlay': isLessThanSm }">
    <NuxtPage />
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/breakpoints.pcss";

.overlay {
  position: relative;
  top: -7rem;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 8500;
  @mixin screen sm {
    width: 100%;
  }
}

.back-button {
  font-size: 1.5rem;
  padding: 0.75rem;
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.85rem;
  height: 2.85rem;
  z-index: 100;
  border-radius: 100%;
  cursor: pointer;
  background-color: rgba(0,0,0,0.6);
  color: var(--color-slate-50);
  @include screen(lg) {
    display: none;
  }
}
.back-button:hover {
  background-color: var(--color-slate-200);
}
</style>
