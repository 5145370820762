export default defineNuxtRouteMiddleware(async () => {
  const supabase = useSupabaseClient()
  const { data: { user }, error: userError } = await supabase.auth.getUser()

  if (userError) {
    return navigateTo('/auth')
  }

  if (!user) {
    return navigateTo('/auth')
  }
})
