<script setup lang="ts">
const { formatedImageUrl } = useStorage()
const { fetchFeaturedCreators }  = useCreators()
const { fetchDestinations } = useMaps()
const { data: featuredCreators } = await useAsyncData('allCreators', async () => {
  return await fetchFeaturedCreators(4)
})
const { data: destinations } = await useAsyncData('featuredDestinations', () => fetchDestinations(5))
const { currentUser} = useAuth()
const { openDialog } = useDialog()

const faqItems = [
  {
    id: '1',
    header: 'What is Mappr?',
    content: 'Mappr is a way to support your favorite creators and have access to their knowledge of the world.'
  },
  {
    id: '2',
    header: 'How does Mappr work?',
    content: 'We share revenue from subscriptions with our creators, helping to cultavate a rich commmunity of creators and Explorers.'
  },
  {
    id: '3',
    header: 'How can I get involved?',
    content: 'You can join mappr as a creator or simply create an account to start saving spots, building your atlas and more.'
  }
]

onMounted(async () => {
  const script = document.createElement('script')
  script.src = 'https://js.stripe.com/v3/buy-button.js'
  document.body.appendChild(script)
})

const { trackEvent } = useMetrics()
const pricingDialogIsOpen = ref(false)
const pricingDialog = useConfirmDialog(pricingDialogIsOpen)
const pricingTier = ref<PricingTier>('premium')
const openPricingDialog = (tier:PricingTier) => {
  pricingTier.value = tier
  pricingDialog.reveal()
  trackEvent(`${tier}_intent`, {
    isHompage: true
  })
}
const closePricingDialog = () => {
  // MAP-100 track event close here
  pricingDialog.cancel()
  trackEvent('closed_pricing_dialog', {
    isHompage: true
  })
}
</script>

<template>
  <div class="home-page">
    <MPHero class="hero" />
    <section class="for-creators">
      <MPSectionHeader
        subtitle="Creator enabled"
        title="Curated by Those Exploring the World."
        summary="Unlock insights from real creators, discover hidden gems and secret spots that go beyond the usual travel guides."
        class="header"
      />
      <div class="image-grid">
        <NuxtLinkLocale v-for="creator in featuredCreators" :key="creator.id" :to="`/@${creator.mappr_handle}`">
          <MPImg :src="creator.profile_image_url" :width="600" :height="600"/>
          <p><strong>@{{ creator.mappr_handle }}'s</strong> <span>{{ creator.maps[0].title }}</span></p>
        </NuxtLinkLocale>
      </div>
    </section>
    <section class="for-explorers">
      <MPSectionHeader
        subtitle="For Explorers"
        title="Treasures off the beaten path"
        summary="Get trusted recommendations by following your favorite creators, from influencers to local experts, ensuring every spot is handpicked and high quality."
        :center-title="false"
      />
      <NuxtLinkLocale to="/maps">
        <MPButton label="Start exploring" rounded/>
      </NuxtLinkLocale>
      <div class="category-grid">
        <div class="category-background">
          <MPSkewContainer
            id="fill-skew"
            fill="var(--color-slate-100)"
            class="fill"
          />
          <MPSkewContainer
            id="outline-skew"
            stroke="var(--color-indigo-200)"
            class="outline"
          />
          <MPImg src="/media/images/sushi-dude.png" class="sushi-dude" />
        </div>
        <NuxtLinkLocale to="/maps?category=Beach" class="category">
          <MPCategoryCard
            :title="$t('home.categoryCards.1.title')"
            :description="$t('home.categoryCards.1.text')"
            image-cover="/media/videos/3-poster.jpg"
            video-src="/media/videos/3.webm"
            copyright-holder="Video by Peggy Anke: https://www.pexels.com/video/woman-laid-in-a-hammock-6628063/"
          />
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/maps?category=Restaurant|Cafe" class="category">
          <MPCategoryCard
            :title="$t('home.categoryCards.2.title')"
            :description="$t('home.categoryCards.2.text')"
            image-cover="/media/videos/4-poster.jpg"
            video-src="/media/videos/4.webm"
            copyright-holder="Video by cottonbro studio: https://www.pexels.com/video/a-person-uses-a-whit-cabbage-vegetable-for-added-toppings-in-a-noodle-soup-bowl-dish-3298229/"
          />
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/maps?category=Bar" class="category">
          <MPCategoryCard
            :title="$t('home.categoryCards.3.title')"
            :description="$t('home.categoryCards.3.text')"
            image-cover="/media/videos/5-poster.jpg"
            video-src="/media/videos/5.webm"
            copyright-holder="Video by Yaroslav Shuraev: https://www.pexels.com/video/friends-walking-outside-7645530/"
          />
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/maps?category=Art+Gallery|Museum" class="category">
          <MPCategoryCard
            :title="$t('home.categoryCards.4.title')"
            :description="$t('home.categoryCards.4.text')"
            image-cover="/media/videos/6-poster.jpg"
            video-src="/media/videos/6.webm"
            copyright-holder="Video by Antoni Shkraba: https://www.pexels.com/video/back-view-of-a-man-and-a-woman-sitting-on-a-couch-while-having-a-conversation-7986422/"
          />
        </NuxtLinkLocale>
      </div>
    </section>
    <section class="studio" :style="`background-image: url('/media/images/callout-studio-background.jpg')`">
      <div>
        <MPSectionHeader
          subtitle="Join Mappr"
          title="Become a Creator"
          summary="Your knowledge of the world is one of your most valuable assets. Share your favourite spots and get paid by your followers."
          invert
        />
        <NuxtLinkLocale to="/creators">
          <MPButton label="Find out how" rounded version="primary"/>
        </NuxtLinkLocale>
        <div class="screenshot">
        <MPSkewContainer
            id="fill-skew"
            fill="var(--color-rose-400)"
            class="fill"
          />
          <MPImg src="/media/images/studio-screenshot.jpg"  />
          <MPSkewContainer
            id="outline-skew"
            stroke="var(--color-rose-200)"
            class="outline"
          />
          <div class="beta-tag">
            <MPTag version="success">New</MPTag>
            Creator Studio Public Beta v0.1
          </div>
        </div>
      </div>
    </section>
    <section class="pricing-section">
      <MPSectionHeader
        subtitle="Membership plans"
        title="Join & Empower Creators "
        summary="Your membership supports creators in their work while giving you unlimited access to exclusive spots, special deals, premium features, and more."
      />
      <div class="pricing-cards">
        <div class="pricing-member">
          <h2>Member</h2>
          <p>Always free</p>
          <span class="price">€0</span>
          <span class="period">Monthly</span><br><br>
          <MPButton v-if="!currentUser" label="Create account" version="default" rounded @click="openDialog()"/>
          <MPButton v-else label="Your already a member" version="secondary" disabled rounded/>
          <ul class="tier-features">
            <li>
              <strong>View all Maps,&nbsp;</strong>
              <p>You can discover all the maps on the platform</p>
            </li>
            <li>
              <strong>Limited access to Spots,&nbsp;</strong>
              <p>You can access spot avalible to members but not supporter or subscriber spots</p>
            </li>
            <li>
              <strong>Limited Saved Spots,&nbsp;</strong>
              <p>You can save spots but you cant manage them in your atlas</p>
            </li>
          </ul>
        </div>
        <div class="pricing-premium">
          <h2>Premium</h2>
          <p>Full Access</p>
          <span class="price">€5</span>
          <span class="period">Monthly</span><br><br>
          <MPButton v-if="currentUser && currentUser.subscriptions?.status !== 'active'" label="Your already a subscriber" version="secondary" disabled rounded/>
          <MPButton v-else label="Go Premium" version="primary" rounded  @click="openPricingDialog('premium')" />
          <ul class="tier-features">
            <li>
              <strong>Unlock All Spots,&nbsp;</strong>
              <p>Access both public and subscriber only spots.</p>
            </li>
            <li>
              <strong>Creator your Atlas,&nbsp;</strong>
              <p>Build a personalized map by saving your favorite spots</p>
            </li>
            <li>
              <strong>Early Access,&nbsp;</strong>
              <p>Be the first to know when your favorite maps are updated.</p>
            </li>
            <li>
              <strong>Support Creators,&nbsp;</strong>
              <p>Help sustain the creators your love through revenue sharing.</p>
            </li>
          </ul>
          <hr>
          <p style="text-align: left;color: var(--color-slate-500);">
            <strong>* Supporter only content</strong><br>
            You can also support a specific creator and get access to supporter exclusive spots
          </p>
        </div>
      </div>
    </section>
    <section class="destinations">
      <MPSectionHeader
        subtitle="Destinations"
        title="A new way to explore the world"
        summary="Get the best travel tips, hidden gems, and global insights from our worldwide community of creators and travel experts."
      />
      <div class="destinations-list">
        <div
          v-for="destination in destinations"
          :key="destination.id"
          class="destinations-item"
          :style="`background-image: url(${formatedImageUrl(destination.cover_image_url, 600, 400)})`"
        >
          <NuxtLinkLocale :to="`/destinations/${destination.id}`">
            <div class="destinations-item-content">
              <h3>{{ destination.name }}</h3>
              <small>{{ destination.country }}</small>
            </div>
          </NuxtLinkLocale>
        </div>
        <div class="destinations-item callout">
          <div class="destinations-item-content">
            <div>
              <h5>Creator curated spots to visit</h5>
              <p>Find things to do, Restaurants to love, Attractions to enjoy & Experiences to remember</p>
            </div>
            <NuxtLinkLocale :to="`/destinations`">
              <MPButton label="Explore Destinations" version="primary" rounded/>
            </NuxtLinkLocale>
          </div>
        </div>
      </div>
    </section>
    <section class="faq">
      <MPSectionHeader
        subtitle="Frequently Asked Questions"
        title="Frequently Asked Questions"
        summary="Get recommendations on spots, Travel info, offers and more from our community of creators & travel experts."
      />
      <MPAccordion
        :items="faqItems"
        class="faq-list"
      />
    </section>
    <MPDialogWrapper
      :is-open="pricingDialogIsOpen"
      is-fullscreen
      @close="closePricingDialog"
    >
      <MPPricing
        :is-open="pricingDialogIsOpen"
        :show-supporter-tier="true"
        @close="pricingDialog.cancel"
      />
    </MPDialogWrapper>
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/breakpoints.pcss";
@import "@/assets/css/typography.pcss";

.home-page {
  position: relative;
  z-index: 0;
  top: -7.5rem;
  @mixin screen sm {
    all: unset;
  }
}

@define-mixin section-gap {
  padding: 4rem 1rem;
  @mixin screen sm {
    padding: 7rem 2rem;
  }
  @mixin screen xl {
    padding: 8rem 1rem;
  }
}

.for-creators {
  margin: 3rem auto 0;
  @mixin section-gap;
  text-align: center;
  max-width: var(--default-content-width);
  .header {
    margin: 0 auto;
  }
  .image-grid {
    margin-top: 6rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 1rem;
    min-height: 70rem;
    @mixin screen xs {
      grid-template-rows: repeat(2, 1fr);
      min-height: 35rem;
    }
    a {
      position: relative;
      border-radius: 0 0 1rem 1rem;
      box-shadow: 0;
      transition: all 0.2s ease-in-out;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1/1;
        border-radius: 1rem;
      }
      p {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 45%, rgba(0, 0, 0, 0.00) 100%);
        color: var(--color-slate-50);
        border-radius: 0 0 1rem 1rem;
        text-align: left;
        padding: 1.5rem;
        margin: 0;
        @mixin typeface-h5;
        span {
          display: block;
          margin-top: 0.5rem;
          @mixin typeface-regular;
          max-width: 20rem;
          color: var(--color-slate-200);
        }
      }
    }
    a:hover {
      box-shadow: var(--shadow-lg);
      transform: translateY(-0.15rem);
      transition: all 0.2s ease-in-out;
    }
    a:nth-child(1) {
      grid-column: 1/5;
      @mixin screen xs {
        grid-column: 1/3;
      }
      @mixin screen md {
        grid-column: 1/1;
      }
    }
    a:nth-child(2) {
      grid-column: 1/5;
      @mixin screen xs {
        grid-column: 3/5;
      }
      @mixin screen md {
        grid-column: 1/2;
      }
    }
    a:nth-child(4) {
      grid-column: 1/5;
      @mixin screen xs {
        grid-column: 3/5;
        grid-row: 2;
      }
      @mixin screen md {
        grid-column: 2;
        grid-row: 1 / span 2;
      }
    }
    a:nth-child(3) {
      grid-column: 1/5;
      @mixin screen xs {
        grid-column: 1/3;
        grid-row: 2;
      }
      @mixin screen md {
        grid-column: 3 / span 2;
        grid-row: 1 / span 2;
        p {
          top: 0;
          height: fit-content;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
          border-radius: 1rem 1rem 0 0;
        }
      }
    }
  }
}

.for-explorers {
  margin: 0 auto;
  @mixin section-gap;
  text-align: left;
  max-width: var(--default-content-width);
  .category-grid {
    position: relative;
    margin: 8rem 0 4rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    gap: 1rem;
    @mixin screen xs {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    @mixin screen lg {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
    }
    a {
      box-shadow: 0;
      transition: all 0.2s ease-in-out;
      border-radius: 1rem;
      max-height: 14rem;
      @mixin screen xs {
        max-height: 18rem;
      }
    }
    a:hover {
      box-shadow: var(--shadow-lg);
      transform: translateY(-0.15rem);
      transition: all 0.2s ease-in-out;
    }
  }
  .category-background {
    position: absolute;
    top: -410px;
    right: -200px;
    @mixin screen sm {
      top: -410px;
      right: 0;
    }
  }
  .outline {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    right: 110px;
    top: 190px;
    @mixin screen sm {
      width: 450px;
      right: 0;
      top: 0;
    }
  }
  .fill {
    position: absolute;
    width: 300px;
    right: 40px;
    top: 140px;
    @mixin screen sm {
      top: -120px;
      right: -200px;
      width: 600px;
    }
  }
  .sushi-dude {
    position: relative;
    z-index: 0;
    width: 150px;
    right: 190px;
    top: 200px;
    @mixin screen sm {
      width: 260px;
      right: 2rem;
      top: 65px;
    }
  }
}

.studio {
  height: 38rem;
  max-height: 100vh;
  background-size: cover;
  background-position: center center;
  display: flex;
  position: relative;
  margin-bottom: 10rem;
  @mixin screen sm {
    height: 50rem;
  }
  > div {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: var(--default-content-width);
    padding: 0 1rem;
    z-index: 1;
    padding: 5rem 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @mixin screen sm {
      padding: 10rem 1rem;
    }
  }
  .screenshot {
    position: absolute;
    bottom: -5%;
    left: 2rem;
    width: 80vw;
    box-shadow: var(--shadow-lg);
    border-radius: 1rem;
    @mixin screen xs {
      bottom: -15%;
      left: 4rem;
      width: 80vw;
    }
    @mixin screen md {
      left: 50%;
      transform: translateX(-50%);
      max-width: 40rem;
    }
    img {
      border-radius: 1rem;
      object-fit: cover;
      width: 100%;
    }
    .beta-tag {
      position: absolute;
      top: -2rem;
      left: 0;
      color: var(--color-white);
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .fill {
      position: absolute;
      top: -50px;
      right: -5rem;
      width: 150px;
      z-index: -1;
      @mixin screen xs {
        width: 250px;
      }
    }
    .outline {
      position: absolute;
      top: 50px;
      right: -20px;
      width: 100px;
      @mixin screen xs {
        width: 200px;
      }
    }
  }
}
.studio:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 1) 100%);
  backdrop-filter: blur(3px);
  border-radius: 1rem;
}


.pricing-section {
  margin: 2rem auto;
  display: grid;
  text-align: center;
  justify-items: center;
  max-width: var(--default-content-width);
  @mixin screen xs {
    margin-top: 17rem;
    margin-bottom: 2rem;
  }
  .tier-features {
    list-style: none;
    position: relative;
    padding: 0;
    margin: 0 0 0;
    li {
      padding: 0.5rem;
      margin-left: 1.5rem;
      color: var(--color-slate-500);
      line-height: 1.35;
      strong {
        display: inline;
        margin: 0;
      }
      p {
        display: inline;
      }
    }
    li:before {
      content: "✓";
      color: var(--color-green-500);
      position: absolute;
      left: -0.5rem;
      font-size: 1.25rem;
    }
  }
  .pricing-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    margin: 2rem 0;
    @mixin screen sm {
      grid-template-columns: repeat(2, 1fr);
    }
    .pricing-member,
    .pricing-premium {
      box-shadow: var(--shadow-base);
      height: fit-content;
      padding: 1rem 3rem 1rem;
      border-radius: 1rem;
      border: 1px solid var(--color-slate-100);
      background-color: #fff;
      max-width: 23rem;
      .price {
        @mixin typeface-h2;
      }
      .period {
        @mixin typeface-summary;
      }
      ul {
        @mixin typeface-regular;
        text-align: left;
        margin: 2rem 0 1rem;
        padding: 0;
        li {
          margin: 0.5rem;
        }
      }
    }
    .pricing-member {
      padding: 2rem 3rem 2rem;
      @mixin screen sm {
        grid-template-columns: repeat(2, 1fr);
        transform: translateX(1rem) translateY(15%);
      }
    }
    .pricing-premium {
      box-shadow: var(--shadow-lg);
      padding: 2rem 3rem 2rem;
      @mixin screen sm {
        grid-template-columns: repeat(2, 1fr);
        transform: translateX(-3rem);
      }
    }
  }
}

.destinations {
  margin: 0 auto;
  @mixin section-gap;
  display: grid;
  text-align: center;
  justify-items: center;
  max-width: var(--default-content-width);
}
.destinations-list {
  margin-top: 2rem;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 0 1rem;
  grid-auto-flow: dense;
  @mixin screen sm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    > :nth-child(5),
    > :nth-child(3) {
      grid-column: span 2;
    }
  }
  @mixin screen md {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    > :nth-child(5),
    > :nth-child(3) {
      grid-column: span 2;
    }
  }
  @mixin screen lg {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    > :nth-child(5),
    > :nth-child(3) {
      grid-column: span 2;
    }
  }
}
.destinations-item {
  height: 12rem;
  border-radius: 1rem;
  background-position: center;
  background-size: cover;
  color: var(--color-slate-50);
  background-color: var(--color-slate-500);
  @mixin screen sm {
    height: 18rem;
  }
}
.destinations-item-content {
  text-align: left;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: bottom;
  padding: 1rem;
  width: 100%;
  height: 100%;
  background: linear-gradient(-20deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.7) 100%);
  h3 {
    margin: 0;
    @mixin typeface-h5;
  }
  small {
    color: var(--color-slate-200);
  }
}

.callout {
  background-color: var(--color-green-500);
  background-color: var(--color-slate-100);
  color: var(--color-slate-900);
  border: 1px solid var(--color-slate-200);
  .destinations-item-content {
    justify-content: space-between;
    background-image: none;
    padding: 1.5rem;
    h5 {
      margin: 0;
    }
    p {
      color: var(--color-slate-500);
    }
  }
}

.faq {
  margin: 0 auto;
  @mixin section-gap;
  max-width: var(--default-content-width);
  display: grid;
  text-align: center;
  justify-items: center;
}
.faq-list {
  max-width: 40rem;
  margin: 0 auto;
}
</style>
