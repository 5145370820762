<script lang="ts" setup>
import type { Tables } from '@/types/database.types'

type PricingTier = 'premium' | 'supporter'
const props = defineProps<{
  isOpen: boolean;
  showSupportTier: boolean;
  creator: Tables<'profiles'>;
}>()

const interval = ref('month')
const activeCheckout = ref(false)
const config = useRuntimeConfig()
const selectedPriceId = ref(config.public.pricingIdPremiumMonth)
const selectedTier = ref<PricingTier | null>(null)
const checkoutPremium = () => {
  if (interval.value === 'month') {
    selectedPriceId.value = config.public.pricingIdPremiumMonth
  } else {
    selectedPriceId.value = config.public.pricingIdPremiumYear
  }
  selectedTier.value = 'premium'
  activeCheckout.value = true
}

const checkoutSupport = () => {
  if (interval.value === 'month') {
    selectedPriceId.value = config.public.pricingIdSupportMonth
  } else {
    selectedPriceId.value = config.public.pricingIdSupportYear
  }
  selectedTier.value = 'supporter'
  activeCheckout.value = true
}

</script>

<template>
  <div class="pricing">
    <section>
      <MPSectionHeader
        v-if="!activeCheckout"
        title="Join & Empower Creators"
        subtitle="Membership plans"
        summary="Your membership supports creators in their work while giving you unlimited access to a real and honest recommendations."
        center-title
      />
      <div v-if="!activeCheckout" class="intervals">
        <div class="intervals-toggle">
          <button :class="[ interval === 'month' ? 'active' : '']" @click="interval = 'month'">
            Monthly
          </button>
          <button :class="[ interval === 'year' ? 'active' : '']" @click="interval = 'year'">
            Yearly
          </button>
        </div>
      </div>
      <Transition name="fade">
        <div v-if="!activeCheckout" class="tiers">
          <div class="tier">
            <div class="tier-header">
              <h2>Premium</h2>
              <p>{{ interval === 'month' ? '€5/month' : '€48/year' }}</p>
            </div>
            <MPButton
              label="Go Premium"
              rounded
              version="primary"
              outline
              @click="checkoutPremium"
            />
            <p class="tier-highlight">
              30 day free trial
            </p>
            <ul class="tier-features">
              <li>
                <strong>Unlock All Spots,&nbsp;</strong>
                <p>Access both public and subscriber only spots.</p>
              </li>
              <li>
                <strong>Creator your Atlas,&nbsp;</strong>
                <p>Build a personalized map by saving your favorite spots</p>
              </li>
              <li>
                <strong>Early Access,&nbsp;</strong>
                <p>Be the first to know when your favorite maps are updated.</p>
              </li>
              <li>
                <strong>Support Creators,&nbsp;</strong>
                <p>Help sustain the creators your love through revenue sharing.</p>
              </li>
            </ul>
          </div>
          <div v-if="props.creator && props.creator.stripe_account_id" class="tier active">
            <div class="tier-header">
              <h2>Supporter</h2>
              <p>{{ interval === 'month' ? '€10/month' : '€100/year' }}</p>
            </div>
            <div class="creator-info">
              <MPImg
                :src="props.creator.profile_image_url"
                :alt="`support ${props.creator.display_name}`"
                :width="100"
                :height="100"
                class="creator-img"
              />
              <small>@{{ props.creator.mappr_handle }}</small>
            </div>
            <MPButton
              :label="`Support ${props.creator.display_name}`"
              rounded
              version="primary"
              @click="checkoutSupport"
            />
            <p class="tier-highlight">
              30 day free trial
            </p>
            <ul class="tier-features">
              <li>
                <strong>Premium Subscription Included,&nbsp;</strong>
                <p>Includes all features available in the Premium Plan.</p>
              </li>
              <li>
                <strong>Exclusive Spots Access,&nbsp;</strong>
                <p>Enjoy exclusive supporter-only spots from chosen creators.</p>
              </li>
              <li>
                <strong>Direct Creator Support,&nbsp;</strong>
                <p>Support <span>{{ props.creator.display_name }}</span> directly through Mappr.</p>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <MPCheckout
            :selected-price-id="selectedPriceId"
            :tier="selectedTier"
            :creator="props.creator"
          />
        </div>
      </Transition>
      <p v-if="!activeCheckout" class="disclaimer">
        <small>
          The free trial will provide you 30 days of a paid subscription to Mappr Premium, free of charge. After 30 days, you will automatically be charged $5 on a monthly basis. You can cancel anytime.
        </small>
      </p>
    </section>
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/typography.pcss";
@import "@/assets/css/breakpoints.pcss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.pricing {
  width: 100%;
  height: 100%;
}

section {
  display: grid;
  text-align: center;
  align-items: center;
  margin-top: 2rem;
}

.intervals {
  display: flex;
  margin: 0 auto;
  gap: 1rem;
}

.tiers {
  text-align: left;
  display: grid;
  gap: 2rem;
  grid-auto-flow: row;
  margin: 0 auto;
  margin-top: 3rem;

  @mixin screen sm {
    grid-auto-flow: column;
  }
}

.tier {
  position: relative;
  border: 1px solid var(--color-slate-200);
  border-radius: 1rem;
  padding: 1.75rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 21rem;
  &.active {
    border-color: var(--color-rose-300);
    box-shadow: var(--shadow-md)
  }
}

.tier-highlight {
  color: var(--color-gray-500);
  @mixin typeface-small;
  margin-top: 1.2rem;
}

.tier-features {
  list-style: none;
  position: relative;
  padding: 0;
  margin: 0 0 0;
  li {
    padding: 0.5rem;
    margin-left: 1.5rem;
    color: var(--color-slate-500);
    line-height: 1.35;
    strong {
      display: inline;
      margin: 0;
    }
    p {
      display: inline;
    }
  }
  li:before {
    content: "✓";
    color: var(--color-green-500);
    position: absolute;
    left: 0;
    font-size: 1.25rem;
  }
}

.tier-header {
  h2 {
    margin-bottom: 0.5rem;
  }
  p {
    color: var(--color-slate-400);
  }
}

.creator-info {
  position: absolute;
  display: grid;
  top: 0.95rem;
  right: 1rem;
  @mixin screen xs {
    top: 2rem;
  }
  small {
    color: var(--color-rose-500);
    margin-top: 0.5rem;
  }
}

.creator-img {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  margin: 0 auto;
}

.disclaimer {
  color: var(--color-slate-500);
  @mixin typeface-detail;
  text-align: center;
  margin: 2rem auto 4rem;
  padding: 0 1.5rem;
  line-height: 1.35;
  max-width: 40rem;
}

.intervals-toggle {
  background: var(--color-slate-100);
  border-radius: 2rem;
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
  button {
    @mixin typeface-regular;
    border: none;
    outline: none;
    background: transparent;
    padding: 0.75rem 1rem;
    border-radius: 3rem;
    color: var(--color-slate-600);
    cursor: pointer;

  }
  button.active {
    @mixin typeface-regular;
    box-shadow: var(--shadow-md);
    color: var(--color-slate-50);
    background: var(--color-slate-700);
  }
}
</style>
