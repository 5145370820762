export const useCreators = () => {
  const { t } = useI18n()
  const { showError } = useToast()
  const supabase = useSupabaseClient()

  const fetchCreatorById = async (creatorId: string) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', creatorId)
      .single()
    if (error) showError('Could not fetch info')
    return data
  }

  const fetchCreators = async (filters, search) => {
    let query = supabase
      .from('profiles')
      .select(`
        *,
        maps (
          id,
          title,
          cover_image_url,
          spots_count
        )
      `)
      .eq('is_creator', true)
      .eq('is_published', true)
    for (const filter of filters) {
      query = query[filter.operator](filter.value, filter.arg)
    }
    if (search) {
      query = query.ilike('mappr_handle', `%${search}%`)
    }
    const { data, error } = await query
    if (error) showError(t('messages.maps.error-loading'))
    return data
  }

  const fetchRecentCreators = async () => {
    const { data, error } = await useSupabaseClient()
      .from('profiles')
      .select('*')
      .eq('is_creator', true)
      .limit(5)
    if (error) showError(t('messages.creators.notFetchCreators'))
    return data
  }

  const fetchFeaturedCreators = async (limit = 5) => {
    const { data, error } = await useSupabaseClient()
      .from('profiles')
      .select(`
        *,
        maps (
          id,
          title,
          cover_image_url,
          spots_count
        )
      `)
      .eq('is_featured', true)
      .eq('is_creator', true)
      .order('updated_at', { ascending: false })
      .limit(limit)
    if (error) showError(t('messages.creators.notFetchCreators'))
    return data
  }

  return {
    fetchRecentCreators,
    fetchFeaturedCreators,
    fetchCreatorById,
    fetchCreators
  }
}
