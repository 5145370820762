/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
  interface Window {
    Fernand: any;
  }
}

export default defineNuxtPlugin(() => {
  if (import.meta.client) {
    if (typeof window.Fernand !== 'function') {
      const f = function () {
        // eslint-disable-next-line prefer-rest-params
        f.q[arguments[0] === 'set' ? 'unshift' : 'push'](arguments)
      }
      f.q = []
      window.Fernand = f
    }
    window.Fernand('init', {
      appId: 'mappr',
      orgName: 'Mappr.com',
      sound: false,
      tabs: [
        // 'home',
        'chat',
        // 'contact'
      ],
      theme: 'light',
      accentColor: 'var(--color-rose-400)',
      iconColor: 'var(--color-rose-50)',
    })

    const script = document.createElement('script')
      script.src = 'https://messenger.getfernand.com/client.js'
      script.async = true
      document.head.appendChild(script)

    return {
      provide: {
        fernand: () => window.Fernand
      }
    }

  }
})
