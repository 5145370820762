import * as Sentry from '@sentry/vue'

export default defineNuxtRouteMiddleware(async (to) => {
  // if (import.meta.env.MODE === 'development') return
  const supabase = useSupabaseClient()
  const { data: { user }} = await supabase.auth.getUser()
  const { $posthog } = useNuxtApp()
  const posthog = $posthog()
  if (user) {
    posthog.identify(user.id, { email: user.email })
    Sentry.setUser({ id: user.id, email: user.email })
  }
  nextTick(() => {
    posthog.capture('$pageview', {
      current_url: to.fullPath
    })
  })
})
