<script setup lang="ts">
const props = defineProps({
  words: {
    type: Array as () => string[],
    required: true
  },
  firstLine: {
    type: Boolean,
    default: true
  }
})

const currentWordIndex = ref(0)

const changeWord = () => {
  currentWordIndex.value = (currentWordIndex.value + 1) % props.words.length
}

onMounted(() => {
  setInterval(changeWord, 5000)
})
</script>
<template>
  <article>
    <h1 class="title">
      <div>
        <slot class="top-title" name="title" />
        <span v-if="firstLine">
        <span
          v-for="(word, index) in words"
          :key="word"
          class="highlight"
          :class="{
            'active': index === currentWordIndex,
            'next': index === (currentWordIndex + 1) % words.length,
            'previous': index === (currentWordIndex - 1 + words.length) % words.length,
            'before-next': index === (currentWordIndex + 2) % words.length
          }"
        >
          &nbsp;{{ word }};
        </span>
        </span>
      </div>
      <slot class="bottom-title" name="titleContinued" />
      <span v-if="!firstLine">
      <span
        v-for="(word, index) in words"
        :key="word"
        class="highlight"
        :class="{
          'active': index === currentWordIndex,
          'next': index === (currentWordIndex + 1) % words.length,
          'previous': index === (currentWordIndex - 1 + words.length) % words.length,
          'before-next': index === (currentWordIndex + 2) % words.length
        }"
      >
        {{ word }};
      </span>
      </span>
    </h1>
    <p class="summary">
      <slot name="summary" />
    </p>
    <div class="buttons">
      <slot name="buttons" />
    </div>
  </article>
</template>
<style lang="postcss" scoped>
@import "@/assets/css/typography.pcss";
@import "@/assets/css/shadow.pcss";
@import "@/assets/css/breakpoints.pcss";

.first-title {
  margin-left: -4rem;
}

article {
  position: relative;

  .pill {
    margin-top: 3rem;
    padding-bottom: 0.5rem;
    color: var(--color-rose-400);
  }

  .title {
    @mixin typeface-huge;
    position: relative;
    width: 100%;
    margin: 0;
    margin-bottom: 0;
    color: white;
    @mixin screen sm {
      color: var(--color-black);
    }
    > div {

      @mixin screen xs {
        transform: translateX(0);
      }
    }
  }
  span {
    color: var(--color-rose-400);
    @mixin screen sm {
      color: var(--color-rose-400);
    }
  }
  .highlight {
    position: absolute;
    transition: transform 1s, opacity 1s;
    opacity: 0;
    white-space: nowrap;
    /* transform: translateX(-50%); */

    &.active {
      opacity: 1;
      transform: translate(-50%, 0);
      @mixin screen sm {
        transform: translate(0, 0);
      }
    }

    &.previous {
      opacity: 0;
      transform: translate(-50%, -25%);
      @mixin screen sm {
        transform: translate(0, -25%);
      }
    }

    &.next {
      opacity: 0.1;
      transform: translate(-50%, 100%);
      @mixin screen sm {
        transform: translate(0, 100%);
      }

    }
    &.before-next {
      opacity: 0;
      transform: translate(-50%, 200%);
      @mixin screen sm {
        transform: translate(0, 200%);
      }
    }
  }
  .summary {
    @mixin typeface-summary;
    color: var(--color-white);
    width: 100%;
    max-width: 20rem;
    margin-bottom: 1.5rem;
    margin-top: 0.75rem;
    line-height: 1.25;
    @mixin screen sm {
      max-width: 30rem;
      margin-top: 1.75rem;
      margin-bottom: 2.5rem;
      color: var(--color-slate-600);
    }
  }
  .buttons {
    width: 100%;
    display: grid;
    gap: 0.5rem;
    padding: 0;
    margin-bottom: 2rem;
    @mixin screen sm {
      display: flex;
      margin-bottom: 4rem;
    }
  }
}
</style>
