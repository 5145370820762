<script lang="ts" setup>
import { z } from 'zod'

const props = defineProps({
  login: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: 'Join or Sign in'
  },
  summary: {
    type: String,
    default: 'Access Bookmarks, Members only content, and more'
  },
  startOn: {
    type: String,
    default: 'login'
  },
  showFooter: {
    type: Boolean,
    default: true
  }
})

const isLoading = ref(false)
const isSent = ref(false)

const emit = defineEmits(['onCancel', 'onAuthSuccess', 'onSignupSuccess'])

const { t } = useNuxtApp().$i18n
const { showError } = useToast()
const supabase = useSupabaseClient()
const { signInWithEmail } = useAuth()

// auth step
const authStep = ref('login')
if (props.startOn) authStep.value = (props.startOn)
const toggleAuthStep = () => {
  authStep.value = authStep.value === 'login' ? 'signup' : 'login'
}

// Login form
const loginModel = reactive({
  email: '',
  password: ''
})

const loginSchema = z.object({
  email: z
    .string({ message: 'Email is required' })
    .email({ message: 'Please enter a valid email address' }),
  password: z
    .string({ message: 'Password is required' })
    .min(8, { message: 'Password must be at least 8 characters long' })
    .max(255, { message: 'Password must be at most 255 characters long' }),
})

// Signup form
const signupModel = reactive({
  email: '',
  password: '',
  confirmPassword: ''
})

const signupSchema = z.object({
  email: z
    .string({ message: 'Email is required' })
    .email({ message: 'Please enter a valid email address' }),
  password: z
    .string({ message: 'Password is required' })
    .min(8, { message: 'Password must be at least 8 characters long' })
    .max(255, { message: 'Password must be at most 255 characters long' }),
  confirmPassword: z
    .string({ message: 'Confirm password is required' })
    .refine((value) => value === signupModel.password, {
      message: 'Passwords must match'
    })
})

const loginFailed = ref(false)
const signupFailed = ref(false)

const handleLogin = async () => {
  console.log(loginSchema.safeParse(loginModel))
  const validationResult = loginSchema.safeParse(loginModel)
  if (!validationResult.success) return
  try {
    isLoading.value = true
    const data = await signInWithEmail(loginModel.email, loginModel.password)
    if (data) emit('onAuthSuccess', data)
    loginFailed.value = false
  } catch (e) {
    loginFailed.value = true
    showError(t('auth.login.failed'), e)
  } finally {
    isLoading.value = false
  }
}

const { trackEvent } = useMetrics()

const handleSignup = async () => {
  const validationResult = signupSchema.safeParse(signupModel)
  if (!validationResult.success) return

  try {
    isLoading.value = true
    const { data, error } = await supabase.auth.signUp({
      email: signupModel.email,
      password: signupModel.password
    })

    if (error) {
      showError(t('auth.login.signupFailed'))
    } else {
      emit('onSignupSuccess', data)
      isSent.value = true
      signupFailed.value = false
      trackEvent('joined')
    }
  } catch (e) {
    showError(t('auth.login.signupFailed'), e)
    signupFailed.value = true
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="authentication-form">
    <div v-if="props.logo" class="auth-logo">
      <img src="/logos/Profile-SocialMedia_500px_C001.svg" width="80px">
    </div>

    <h4 v-if="props.title" class="auth-title">
      {{ props.title }}
    </h4>
    <div v-if="props.summary" class="auth-summary">
      {{ props.summary }}
    </div>
    <MPMessage v-if="loginFailed" version="error">
      {{ $t('auth.login.loginFailed') }}
    </MPMessage>
    <MPMessage v-if="signupFailed" version="error">
      {{ $t('auth.login.signupFailed') }}
    </MPMessage>
    <form v-if="authStep === 'login'" class="login" @submit.prevent="handleLogin">
      <div>
        <MPInput
          v-model="loginModel.email"
          name="email"
          :label="$t('auth.login.email')"
          type="email"
          :placeholder="$t('auth.login.email')"
          class="input-email"
          :validation="loginSchema.shape.email"
          required
          hide-label
        />
      </div>
      <div>
        <MPInput
          v-model="loginModel.password"
          name="password"
          type="password"
          :label="$t('auth.login.password')"
          :placeholder="$t('auth.login.password')"
          :validation="loginSchema.shape.password"
          toggle-mask
          hide-label
          required
          autocomplete
        />
      </div>
      <MPButton
        type="submit"
        :disabled="isLoading"
        :is-loading="isLoading"
        :label="$t('auth.login.title')"
      />
      <MPButton
        label="Join"
        version="secondary"
        @click="toggleAuthStep"
      />
      <NuxtLink to="forgot-password">
        {{ $t('auth.login.forgot') }}
      </NuxtLink>
    </form>

    <!-- Signup Success Message -->
    <p v-if="isSent" class="signup-success">
      Check your email for a link to confirm your account
    </p>

    <!-- Signup Form -->
    <form v-if="authStep === 'signup' && !isSent" class="login" @submit.prevent="handleSignup">
      <MPInput
        v-model="signupModel.email"
        name="email"
        :label="$t('auth.login.email')"
        type="email"
        :placeholder="$t('auth.login.email')"
        class="input-email"
        :validation="signupSchema.shape.email"
        hide-label
        required
      />
      <MPInput
        v-model="signupModel.password"
        name="password"
        type="password"
        :label="$t('auth.login.password')"
        :placeholder="$t('auth.login.password')"
        :validation="signupSchema.shape.password"
        toggle-mask
        hide-label
        required
        autocomplete
      />
      <MPInput
        v-model="signupModel.confirmPassword"
        name="confirmPassword"
        type="password"
        :label="$t('auth.login.confirmPassword')"
        :placeholder="$t('auth.login.confirmPassword')"
        :validation="signupSchema.shape.confirmPassword"
        toggle-mask
        hide-label
        required
        autocomplete
      />
      <MPButton
        :label="$t('auth.signup.title')"
        type="submit"
        :disabled="isLoading"
        :is-loading="isLoading"
      />
      <MPButton
        label="Login"
        version="secondary"
        @click="toggleAuthStep"
      />
    </form>
    <p v-if="showFooter" class="auth-footer">
      <!-- <MPButton link inline @click="toggleAuthStep">
        {{ $t(`auth.${authStep === 'login' ? 'login.noAccount' : 'signup.haveAccount'}`) }}
      </MPButton><br> -->
      <small>
        {{ $t('auth.signup.agreeToTerms') }}
      </small>
    </p>
  </div>
</template>

<style lang="postcss" scoped>
.authentication-form {
  max-width: 20rem;
  margin: 0 auto;
  padding: 1rem;
  display: grid;
  gap: 1rem;
  text-align: center;
}

.login {
  display: grid;
  gap: 1rem;
}

.auth-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-slate-800);
  margin: 0.5rem 0 0 0;
}

.auth-summary {
  color: var(--color-slate-400);
  margin-bottom: 0.75rem;
}

a {
  cursor: pointer;
  text-align: center;
}

.auth-footer {
  color: var(--color-slate-400);
}
</style>
