<script lang="ts" setup>
const { signOut, currentUser, fetchCurrentUser } = useAuth()
await useAsyncData('user', fetchCurrentUser)

const { openDialog } = useDialog()

const route = useRoute()
const isLessThanSm = ref<boolean>(true)

const elBody = window?.document?.body
useResizeObserver(elBody, (entries) => {
  const { width } = entries[0].contentRect
  isLessThanSm.value = width < 756
})

const searchActive = ref(false)
const invertedMenuColor = computed(() => {
  if (route.name) {
    if (route.name.includes('index___') && isLessThanSm.value) return true
    if (route.name.includes('maps-mapId___') && !route.name.includes('studio-') || route.name.includes('maps-mapId-spots-spotId___')) return true
    if (route.name.includes('studio___')) return true
    if (route.name.includes('creators___') && isLessThanSm.value) return true
  }
  return false
})
// const isHomePage = computed(() => {
//   const pathSegments = route.path.split('/')[1] === 'studio'
// })
// const isMapPage = computed(() => {
//   const pathSegments = route.path.split('/')
//   return pathSegments[1] === 'maps' && pathSegments[2] !== undefined
// })

const { trackEvent } = useMetrics()
const handleJoin = () => {
  openDialog()
  trackEvent('join_intent')
}

// User Dropdown Logic
const userDropdownActive = ref(false)
const toggleUserDropdown = () => {
  userDropdownActive.value = !userDropdownActive.value
}

const clickOutside = (event: MouseEvent) => {
  const userDorpdown = document.querySelector('.user-dropdown')
  if (userDorpdown && !userDorpdown.contains(event.target as Node)) {
    userDropdownActive.value = false
  }
}
onMounted(() => {
  document.addEventListener('click', clickOutside)
})
onUnmounted(() => {
  document.removeEventListener('click', clickOutside)
})

const showHeader = ref(false)
onMounted(() => {
  showHeader.value = true
})
</script>

<template>
  <header ref="elHeader" :class="[{ 'invert': invertedMenuColor }, { 'show': showHeader }]">
    <NuxtLinkLocale id="logo-link" aira-lable="Home" to="/">
      <MPLogo id="logo" :logo-color="invertedMenuColor ? 'white' : 'black'" />
    </NuxtLinkLocale>
    <nav id="main-nav" aria-label="Main Navigation" :class="{'search-active': searchActive }">
      <ol role="list">
        <li>
          <NuxtLinkLocale
            to="/"
            aria-current-value="page"
            :class="{'isActive': route.path === '/'}"
          >
            Home
          </NuxtLinkLocale>
        </li>
        <li>
          <NuxtLinkLocale
            to="/maps"
            aria-current-value="page"
            :class="{'isActive': route.path.includes('/maps')}"
          >
            Explore
          </NuxtLinkLocale>
        </li>
        <li>
          <NuxtLinkLocale
            to="/creators"
            aria-current-value="page"
            :class="{'isActive': route.path.includes('/creators')}"
          >
            Creators
          </NuxtLinkLocale>
        </li>
        <li>
          <NuxtLinkLocale
            to="/destinations"
            aria-current-value="page"
            :class="{'isActive': route.path.includes('/destinations')}"
          >
            Destinations
          </NuxtLinkLocale>
        </li>
      </ol>
    </nav>
    <nav id="user-nav" aria-label="User menu">
      <ol>
        <li>
          <MPButton
            v-show="!searchActive && isLessThanSm"
            version="secondary"
            icon="ri-search-line"
            rounded
            class="search-button"
            @click="searchActive = !searchActive"
          />
          <MPButton
            v-show="searchActive && isLessThanSm"
            version="secondary"
            icon="ri-close-line"
            rounded
            @click="searchActive = !searchActive"
          />
          <Transition>
            <MPSearchInline
              v-show="searchActive || !isLessThanSm"
              class="search-input"
            />
          </Transition>
        </li>
        <li v-show="!currentUser && !isLessThanSm">
            <MPButton
              label="Login"
              version="secondary"
              rounded

              @click="openDialog"
            />
        </li>
        <li v-show="!currentUser && !isLessThanSm">
          <MPButton
            label="Join"
            rounded
            @click="handleJoin"
          />
        </li>
        <li v-show="!currentUser && isLessThanSm">
          <MPButton
            icon="ri-user-line"
            rounded
            @click="openDialog"
          />
        </li>
        <li v-show="currentUser" class="user-dropdown" @click="toggleUserDropdown">
          <button class="user-dropdown-trigger">
            <MPImg v-if="currentUser?.profile?.is_creator" :width="90" :height="90" :src="currentUser.profile.profile_image_url" class="user-dropdown-trigger-image" />
            <i v-else class="ri-user-line" />
            <i class="ri-arrow-down-s-line" />
            <!-- <small>{{ currentUser.user.email }}</small> -->
          </button>
          <div v-show="userDropdownActive" class="user-dropdown-content">
            <ol>
              <li>
                <NuxtLinkLocale to="/account">
                  <i class="ri-account-circle-line"/>Account
                </NuxtLinkLocale>
              </li>
              <li v-show="currentUser?.profile?.is_creator || currentUser?.profile?.is_admin">
                <NuxtLinkLocale to="/studio/maps">
                  <i class="ri-megaphone-line"/>Creator Studio
                </NuxtLinkLocale>
              </li>
              <li>
                <NuxtLinkLocale to="/saved-maps">
                  <i class="ri-map-2-line"/>Saved Maps
                </NuxtLinkLocale>
              </li>
              <li>
                <NuxtLinkLocale to="/saved-spots">
                  <i class="ri-hearts-line"/>Saved Spots
                </NuxtLinkLocale>
              </li>
              <li>
                <NuxtLinkLocale to="/" @click="signOut">
                  <i class="ri-logout-circle-r-line"/>Sign Out
                </NuxtLinkLocale>
              </li>
            </ol>
            <!-- <MPMenu
              :items="[
                { label: 'Account', icon: 'ri-account-circle-line', link: '/account' },
                ...(currentUser?.profile?.is_creator || currentUser?.profile?.is_admin ? [{ label: 'Creator Studio', icon: 'ri-megaphone-line', link: '/studio/maps' }] : []),
                { label: 'Saved Maps', icon: 'ri-map-2-line', link: '/saved-maps' },
                { label: 'Saved Spots', icon: 'ri-hearts-line', link: '/saved-spots' },
                { label: 'Sign Out', icon: 'ri-logout-circle-r-line', action: () => signOut()}
              ]"
            /> -->
          </div>
        </li>
      </ol>
    </nav>
  </header>
</template>

<style lang="postcss" scoped>
@import '@/assets/css/breakpoints.pcss';
@import '@/assets/css/typography.pcss';
@import '@/assets/css/layout.pcss';

header.show {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

header {
  @mixin layout-wrapper-wide;
  opacity: 0;
  position: relative;
  z-index: 8000;
  display: grid;
  align-items: center;
  grid-template-areas:
    "logo user-nav"
    "main-nav main-nav";
  grid-template-columns: auto 1fr;
  font-family: "Hanken Grotesk";
  color: var(--color-black);
  @mixin screen sm {
    position: relative;
    gap: 1rem;
    grid-template-areas: "logo main-nav user-nav";
    grid-template-columns: auto 1fr auto;
    margin-top: 0.75rem;
  }
}
header.invert {
  color: var(--color-white);
}

nav#main-nav.search-active {
  opacity: 0;
  @mixin screen sm {
    opacity: 1;
  }
}

nav#main-nav {
  grid-area: main-nav;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 0 1rem 0 1rem;
  scrollbar-width: none;
  @mixin screen sm {
    all: unset;
    grid-area: main-nav;
    padding: 0 0 0 0.5rem;
  }
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-flex;
    gap: 1.5rem;
    align-items: center;
    a {
      position: relative;
      font-size: 1.15rem;
      display: inline-flex;
      padding: 1rem 0;
      white-space: nowrap;
      font-weight: 400;
      transition: font-weight 250ms ease-in-out;
      @mixin screen sm {
        font-size: 1rem;
      }
      &::after {
        content: url('/media/images/link-underline.svg');
        position: absolute;
        bottom: 0.25rem;
        left: 50%;
        opacity: 0;
        transform: translateX(-50%);
        transition: opacity 500ms ease-in-out;
        transition-delay: 50ms;
      }
      &.isActive {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        position: relative;
        transition: font-weight 250ms ease-in-out;
        @mixin screen sm {
          font-size: 1.15rem;
        }
        &::after {
          opacity: 1;
          transition: opacity 500ms ease-in-out;
          transition-delay: 50ms;
        }
      }
    }
  }
}
nav#user-nav {
  grid-area: user-nav;
  margin-left: auto;
  padding: 1rem 1rem 0 0;
  @mixin screen sm {
    padding: 0;
  }
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem
  }
  .user-dropdown {
    position: relative;
    display: inline-flex;
    align-items: center;
    color: inherit;
  }
  .search-button :deep(i) {
    font-size: 1.1rem;
  }
  .user-dropdown-trigger {
    all: unset;
    font-size: 1.5rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    small {
      display: block;
      padding: 1rem;
    }
    img {
      border-radius: 100%;
      display: inline-flex;
      object-fit: cover;
      width: 2.4rem;
      height: 2.4rem;
    }
    i.ri-arrow-down-s-line {
      display: none;
      @mixin screen sm {
        display: block;
      }
    }
  }
  .user-dropdown-content {
    position: absolute;
    margin: 0;
    min-width: 12rem;
    top: 2.75rem;
    right: 0;

    /* transform: translateX(-50%); */
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: var(--shadow-md);
    border: 1px solid var(--color-slate-50);
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 0;
      li {
        width: 100%;
        a {
          display: flex;
          align-items: center;
          padding: 0.75rem 0.75rem;
          font-size: 0.9rem;
          color: var(--color-slate-700);
          cursor: pointer;
          i {
            margin-right: 0.75rem;
            font-size: 1.1rem;
            color: var(--color-slate-500);
          }
          &:hover {
            background-color: var(--color-slate-50);
          }
        }
      }
    }
  }
  .search-input {
    position: absolute;
    left: 0;
    top: 3.5rem;
    padding: 0.5rem;
    @mixin screen sm {
      all: unset;
    }
  }
}
#logo-link {
  display: inline-flex;
}
#logo {
  grid-area: logo;
  padding: 0.75rem 1rem 0;
  height: 3rem;
  @mixin screen sm {
    position: relative;
    top: -0.1rem;
    padding: 0;
    height: 2.5rem;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
